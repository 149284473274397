import { Link, useNavigate, useParams } from "react-router-dom";
import { accessNames, areAllIndicatorsComplete, boldifyName, checkFileValidity, debounce, getSimpleName, round, sortBySequence } from "../helpers";
import { useEffect, useState } from "react";
import { Label, Radio, Select, Textarea, FileInput, Checkbox, TextInput } from "flowbite-react";
import ReactSelect, {components, createFilter} from 'react-select';
import { Controller, useForm } from "react-hook-form"
import { authenticatedApi } from "../Api";
import toast from "react-hot-toast";
import { useAuth } from "../AuthProvider";
import ConfirmDeleteFile from "./ConfirmDeleteFile";
import { DatePicker } from "./DatePicker";
import { parseISO } from "date-fns";
import { BaselineRequirementNoAnswerModal } from "./BaselineRequirementNoAnswerModal";
import { NmsTextarea } from "./NmsTextarea";
import { useDebounce } from "@uidotdev/usehooks";

export const AssessmentBaselineRequirements = (props) => {
    const auth = useAuth();
    const accessLevel = auth.getCommunityAccessLevel();

    const [isUploadingFile, setIsUploadingFile] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [isOverviewCompleted, setIsOverviewCompleted] = useState(false);

    const [isShowingNoAnswerAlert, setIsShowingNoAnswerAlert] = useState(false);
    const [hasShownNoAnswerAlert, setHasShownNoAnswerAlert] = useState(false);

    let defaultValues = {};
    let thisIndicatorEntry = null;
    if(props.entry) {
        thisIndicatorEntry = props.entry.entry.find(obj => obj.standard_id === 'baseline-requirements') ?? null;

        if(thisIndicatorEntry) {
            // correct previous entries that used truthy values
            for(const key in thisIndicatorEntry) {
                if(thisIndicatorEntry[key] === true) {
                    thisIndicatorEntry[key] = 'yes';
                }

                if(thisIndicatorEntry[key] === false) {
                    thisIndicatorEntry[key] = 'no';
                }
            }
            defaultValues = thisIndicatorEntry;
        }
    }

    const { control, register, reset, handleSubmit, watch, getValues, setValue, formState: { errors, isValid, submitCount, dirtyFields } } = useForm({
        mode: 'onTouched',
        defaultValues: defaultValues,
    });


    const doSave = (data) => {
        let _answers = props.entry ? props.entry.entry : [];
        let _data = data;

        // fix strings
        for(const answerKey in _data) {
            if(_data[answerKey] === 'true') {
                _data[answerKey] = true;
            }
            if(_data[answerKey] === 'false') {
                _data[answerKey] = false;
            }
        }

        _data.standard_id = 'baseline-requirements';

        // updating existing answer
        let _index = _answers.findIndex(obj => obj.standard_id === _data.standard_id);
        if(_index !== -1 /*_entry.find(obj => obj.indicator_id === props.indicator.id)*/) {
            _answers[_index] = _data;
        }
        // create new answer
        else
        {
            _answers.push(_data);
        }

        let formEntry;
        if(props.entry) {
            formEntry = {
                entry: _answers,
            };
        } else {
            formEntry = {
                form_id: props.form.id,
                community_id: auth.currentCommunityId,
                status: 'draft',
                entry: _answers,

                form_schema: null,
                entry_schema: null,
            };
        }

        let saveFn = null;
        if(props.entry) {
            saveFn = authenticatedApi.put('form-entries/'+props.entry.id, formEntry);
        } else {
            saveFn = authenticatedApi.post('form-entries', formEntry);
        }
        return saveFn.then(response => {
            let _entry = response.data;
            _entry.entry = typeof _entry.entry === 'string' ? JSON.parse(_entry.entry) : _entry.entry;
            // _entry.entry = JSON.parse(_entry.entry);
            props.setEntry(_entry);
            // props.setEntry(response.data);
        });
    }


    // autosaver for comments
    const autosaveFields = [
        'lp_notes',
        'cp_notes',
        'msa_notes',
    ];

    // autosave
    useEffect(() => {
        const debouncedCb = debounce((formValue) => {
            // console.log(formValue);
            // console.log(dirtyFields);

            const data = getValues();

            let shouldAutosave = false;
            for(const autosaveField of autosaveFields) {
                if(
                    /*!!dirtyFields?.[autosaveField]
                    || */formValue?.[autosaveField] != defaultValues?.[autosaveField]
                    // && !!formValue?.[autosaveField]
                ) {
                    shouldAutosave = true;
                }
            }

            if(shouldAutosave) {
                // console.log('WILL AUTOSAVE');
                doSave(formValue);
                // reset(formValue); // fix default values
            } else {
                // console.log('WILL NOT AUTOSAVE');
            }
        }, 1000);

        const subscription = watch(debouncedCb);

        return () => subscription.unsubscribe();
    }, [watch]);

    useEffect(() => {
        if(props.form && props.entry && props.entry.entry) {
            setIsOverviewCompleted(props.entry.entry.find(obj => obj.standard_id === 'overview') ? true : false);
        } else {
            setIsOverviewCompleted(false);
        }
        //
    }, []);

    // handle resetting form when indicator changes
    useEffect(() => {
        // build initial form state
        let assessmentWorksheet = null;
        if(props.entry) {
            assessmentWorksheet = props.entry.entry.find(obj => obj.standard_id === 'baseline-requirements') ?? null;
        }

        if(assessmentWorksheet === null) {
            assessmentWorksheet = {};

            // TODO: this should probably actually be id-based instead of index-based (not sure how to change without breaking 2023 form)
            let requirementsLength = props.form.baseline_requirements.length;
            for(let i = 0; i < requirementsLength; i++) {
                assessmentWorksheet[`lp_requirement_${i+1}`] = null;
                assessmentWorksheet[`cp_requirement_${i+1}`] = null;
                assessmentWorksheet[`msa_requirement_${i+1}`] = null;
            }

            assessmentWorksheet.lp_notes = null;
            assessmentWorksheet.cp_notes = null;
            assessmentWorksheet.msa_notes = null;
        }
        reset(assessmentWorksheet);
    }, [isOverviewCompleted]);

    if(!isOverviewCompleted) {
        return (<>
            <em className="block mb-3">Please review the Overview before proceeding.</em>
            <Link
                to={`/forms/${props.form.uuid}/overview`}
                className={`self-start inline-flex items-center mt-0 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300`}
            >
                Overview
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </Link>
        </>);
    };

    const onDeleteFile = (newMedia) => {
        const {media, ...prevEntry} = props.entry;
        prevEntry.media = newMedia;
        props.setEntry(prevEntry);
    };

    const onFileChange = (type, event) => {
        if(!checkFileValidity(event)) {
            return;
        }

        setIsUploadingFile(true);

        const formData = new FormData();
        formData.append('section', type);
        // console.log(type);
        formData.append(type, event.target.files[0]);

        authenticatedApi.post('form-entries/'+props.entry.id+'/media', formData).then(response => {
            if(response.data && response.data.media && response.data.media.length) {
                const {media, ...prevEntry} = props.entry;
                prevEntry.media = response.data.media;
                props.setEntry(prevEntry);
                event.target.value = ''; // reset form
            }
        }).finally(() => {
            setIsUploadingFile(false);
        });
    };

    const onSubmit = (data) => {
        setIsLoading(true);

        let saveFn = doSave(data);

        saveFn.then(response => {
            toast.success('Saved!');
            // navigate(`/forms/${props.form.uuid}`);
            navigate(`/forms/${props.form.uuid}/${props.form.standards[0].id}`);
        }).finally(() => {
            setIsLoading(false);
        });

        // todo: display error

        return true;
    };

    const ContinueButton = () => {
        /*
        const {nextText, nextLink} = getNextLink();

        if(nextLink === '') {
            return null;
        }
        */

        let buttonClasses = [];
        if(!isValid) {
            buttonClasses.push('cursor-not-allowed opacity-75 !bg-gray-500');
        } else if(isLoading) {
            buttonClasses.push('cursor-wait');
            buttonClasses.push('opacity-75');
        } else {
            buttonClasses.push('cursor-pointer');
        }

        return (<>
            <button
                type="submit"
                // onClick={onClick}
                className={`self-start inline-flex items-center mt-3 mb-3 py-2 px-3 text-sm font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 ${buttonClasses.join(' ')}`}
            >
                {getSimpleName(props.form.standards[0].name)}
                <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
            </button>
            {isLoading && <span className="inline-block ml-3 italic text-sm text-gray-400">Saving...</span>}
        </>);
    }

    let answersToShow = ['lp'];
    if(accessLevel !== 'lp') {
        answersToShow.push('cp');
    }
    if(accessLevel === 'msa') {
        answersToShow.push('msa');
    }

    return (<>
        <BaselineRequirementNoAnswerModal
            open={isShowingNoAnswerAlert}
            onOpen={()=>setIsShowingNoAnswerAlert(true)}
            onClose={()=>setIsShowingNoAnswerAlert(false)}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
        {/*
        <h1 className="text-3xl font-semibold">Community Assessment Worksheet</h1>
        <h1 className="font-semibold">Evaluation Worksheet for Local Programs, Coordinating Programs, and Main Street America*</h1>
        <div className="max-w-4xl text-black">
            The Main Street America Evaluation Framework outlines what it means to be a highly successful Main Street program and sets a path for growth and development for newer programs. Depending on achievement, score, and maturity, programs will either be designated as Affiliate or Accredited.
            <br/><br/>
            After reviewing the Community Self-Assessment Tool document, use this worksheet to score community progress and determine the designation status of a community. All scores will be averaged and populated at the end.
        </div>
        */}

        {/* <h2 className="mt-6 text-2xl font-semibold">Baseline Requirements</h2> */}
        <h1 className="text-3xl font-semibold">Baseline Requirements</h1>
        <p className="mb-3 max-w-4xl text-black">
            As detailed within the new Community Evaluation Framework Self-Assessment Tool, some indicators are required as important baselines for a program to qualify for Accreditation. Please indicate whether your program meets the baseline requirements by selecting "Yes" or "No" to the questions below and upload documentation where required. Click the "View Sample File" button to download sample/template documents.
        </p>
        <p className="max-w-4xl italic text-black">
            Note: Uploaded documents do not need to be identical to examples, but must have all the information from the examples.
        </p>

        <div>
            <div className={`py-3 flex font-bold`}>
                <div className="flex-auto">{/* Requirement */}</div>
                <div className={`flex-initial text-center min-w-[140px]`} title="Local Program">LP</div>
            </div>
            {props.form.baseline_requirements.sort(sortBySequence).map((requirement, index) => {
                return (
                    <div className={`px-6 py-3 mb-3 gap-6 flex flex-col sm:flex-row md:flex-col lg:flex-row rounded shadow bg-white`} key={index}>
                        <div className="flex-auto">
                            <div dangerouslySetInnerHTML={{__html: boldifyName(`${requirement.name}. ${requirement.description.replaceAll('<p>', '<p style="display:inline">')}`) }}></div>
                            {watch(`lp_requirement_${requirement.id}`) != 'no' && (<>
                            {requirement.has_file_upload == true && (
                                <div className="max-w-xl mt-3">
                                <FileInput
                                    id={`baseline_${requirement.id}`}
                                    size={'sm'}
                                    onChange={e => onFileChange(`baseline_${requirement.id}`, e)}
                                    helperText={isUploadingFile ? 'Uploading...' : 'Files must be no larger than 50mb'}
                                    disabled={isUploadingFile}
                                />

                                {props.entry.media.filter(media => media.collection_name == `baseline_${requirement.id}`).length > 0 && (
                                    <table className="table-auto w-full rounded overflow-hidden bg-white mt-6">
                                        <tbody>
                                        {props.entry.media.filter(media => media.collection_name == `baseline_${requirement.id}`).map((media, mediaIndex) => (
                                            <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-3 py-1.5 ${mediaIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={mediaIndex}>
                                                <td className="px-3 py-1.5"><a href={media.original_url} target="_blank">{media.file_name}</a></td>
                                                <td className="px-3 py-1.5 text-right"><ConfirmDeleteFile media={media} onDelete={onDeleteFile} /></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                )}
                                </div>
                            )}
                            {requirement.media.map((media, mediaIndex) => (
                                <a
                                    key={mediaIndex}
                                    href={media.original_url}
                                    target="_blank"
                                    className={`self-start inline-flex items-center mt-3 py-1 px-1.5 text-xs font-medium text-center text-white bg-green-500 rounded shadow hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300`}
                                >
                                    {/* {button.file_name} */}
                                    View Sample File
                                    <svg className="ml-1 -mr-0.5 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                </a>
                            ))}

                            {Array.isArray(requirement.additional_schema) && requirement.additional_schema.length > 0 && (<>
                            {requirement.additional_schema.map((question, index) => {
                                const answerKey = `lp_requirement_${requirement.id}_answer_${question?.id ?? index}`; // todo: determine if this needs to allow cp/msa answers

                                const requirementAnswer = watch(`lp_requirement_${requirement.id}`);

                                let questionRequired = !!(question?.required);
                                let questionText = question.question;
                                if(requirementAnswer === 'no') {
                                    questionRequired = false;
                                }
                                if(questionRequired && requirementAnswer !== 'no') {
                                    let requiredText = ` <span class="text-red-800">(Required)</span>`;
                                    if(questionText.match(/<\/p>$/)) {
                                        questionText = questionText.replace(/<\/p>$/, `${requiredText}</p>`);
                                    } else {
                                        questionText += requiredText;
                                    }
                                }

                                // select- / multiselect-specific
                                let choices = [...question?.options ?? []] ?? null;
                                if(question.type === 'multichoice' && !!question?.display_other) {
                                    choices.push('Other');
                                }

                                let maxAnswers = 999;//null;
                                if(!!question?.max && parseInt(question.max) > 0) {
                                    let maxText = `<br/><span style="font-size: 0.9rem"><em>(Choose no more than ${question.max})</em></span>`;
                                    if(questionText.match(/<\/p>$/)) {
                                        questionText = questionText.replace(/<\/p>$/, `${maxText}</p>`);
                                    } else {
                                        questionText += maxText;
                                    }
                                    maxAnswers = parseInt(question.max);
                                }

                                let watched_value = watch(answerKey);

                                // date-specific
                                let date_defaultValue;
                                let date_onChange;
                                let date_onBlur;
                                let date_name;
                                if(question.type === 'date') {
                                    const { onChange, onBlur, name: name } = register(answerKey);
                                    date_onChange = onChange;
                                    date_onBlur = onBlur;
                                    date_name = name;
                                    //date_defaultValue = defaultValues[answerKey] ?? new Date;
                                    let _value = watch(answerKey);

                                    date_defaultValue = _value ? (_value instanceof Date ? _value : parseISO(_value)) : '';
                                }

                                return (
                                    <div key={index} className={`max-w-xl mt-6`}>
                                        <div className="mb-3" dangerouslySetInnerHTML={{__html: questionText}}></div>
                                        {question.type === 'text' && (<>
                                            <Textarea
                                                id={answerKey}
                                                className="p-3"
                                                {...register(answerKey, {required: questionRequired})}
                                            ></Textarea>
                                        </>)}
                                        {question.type === 'boolean' && (<div className="flex gap-4">
                                            {['Yes', 'No'].map((answer, answerIndex) => (
                                                <div key={answerIndex}  className="flex items-center gap-2">
                                                    <Radio
                                                        {...register(`${answerKey}`, {required: questionRequired})}
                                                        id={`${answerKey}_${answerIndex}`}
                                                        value={answer.toLocaleLowerCase()}
                                                    />
                                                    <Label htmlFor={`${answerKey}_${answerIndex}`}>
                                                    {answer}
                                                    </Label>
                                                </div>
                                            ))}
                                        </div>)}
                                        {question.type === 'multichoice' && (<>
                                            {choices.length <= 5 && (<>
                                                {choices.map((answer, answerIndex) => (
                                                    <div key={answerIndex} className="flex items-center gap-2">
                                                        <Checkbox
                                                            // id={`${answerKey}_${answerIndex}`}
                                                            {...register(`${answerKey}`, /*{required: questionRequired}*/)}
                                                            id={`${answerKey}_${answerIndex}`}
                                                            value={answer}
                                                            disabled={!!watched_value && watched_value.length >= maxAnswers && watched_value.indexOf(answer) === -1}
                                                        />
                                                        <Label htmlFor={`${answerKey}_${answerIndex}`}>
                                                        {answer}
                                                        </Label>
                                                    </div>
                                                ))}
                                            </>)}
                                            {choices.length > 5 && (<>
                                                <div className='text-black'>
                                                    <Controller
                                                        name={answerKey}
                                                        control={control}
                                                        rules={{required: questionRequired}}
                                                        render={({ field: {name, value, ref, onChange} }) => (
                                                            <ReactSelect
                                                                value={value?.map(opt => ({label: opt, value: opt}))}
                                                                isSearchable={true}
                                                                isMulti
                                                                isOptionDisabled={(option) => option.isDisabled || (value && value.length >= maxAnswers)}
                                                                options={choices?.map(opt => ({label: opt, value: opt})) ?? []}
                                                                placeholder="Choose..."
                                                                onChange={newVal => {
                                                                    // react-select doesn't pass an event
                                                                    onChange({target: {value: newVal.map(v => v.value)}});
                                                                }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary: '#00ABCC',
                                                                        primary75: '#0AD7FF',
                                                                        primary50: '#85EBFF',
                                                                        primary25: '#E0FAFF',
                                                                    },
                                                                })}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        borderRadius: '0.5rem', // .rounded-lg
                                                                        backgroundColor: '#f9fafb', // .bg-gray-50
                                                                        paddingTop: '0.15rem',
                                                                        paddingBottom: '0.15rem',
                                                                        borderColor: '#d1d5db', /* .border-gray-300 */ /*var(--light-gray);*/
                                                                    }),
                                                                    multiValue: (styles, { data }) => {
                                                                        return {
                                                                            ...styles,
                                                                            backgroundColor: '#1F2937', // file uploader button
                                                                            color: '#fff',
                                                                            borderRadius: '0.35rem',
                                                                        }
                                                                    },
                                                                    multiValueLabel: (styles, { data }) => ({
                                                                        ...styles,
                                                                        color: '#fff',//data.color,
                                                                    }),
                                                                    multiValueRemove: (styles, { data }) => ({
                                                                        ...styles,
                                                                        borderTopRightRadius: '0.30rem',
                                                                        borderBottomRightRadius: '0.30rem',
                                                                        // color: '#fff',//data.color,
                                                                    }),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </>)}
                                            {!!question?.display_other && !!watched_value && watched_value.indexOf('Other') !== -1 && (<>
                                                <div className={choices.length > 5 ? 'pt-1' : 'pl-6'}>
                                                <Label htmlFor={`${answerKey}_Other_Text`}>
                                                    Please Specify Other
                                                </Label>
                                                <TextInput
                                                    id={`${answerKey}_Other_Text`}
                                                    className="pt-1"
                                                    {...register(`${answerKey}_Other_Text`, {required: true})}
                                                />
                                                </div>
                                            </>)}
                                        </>)}
                                        {question.type === 'choice' && (<>
                                            <Select id={answerKey} {...register(answerKey, {required: questionRequired})}>
                                                <option value="" disabled>Choose...</option>
                                                {question.options.map(answer => <option value={answer} key={answer}>{answer}</option>)}
                                            </Select>
                                        </>)}
                                        {question.type === 'date' && (<>
                                            <DatePicker
                                                id={answerKey}
                                                {...register(answerKey, {required: questionRequired})}
                                                isClearable
                                                // required={questionRequired}

                                                // onChange={date_onChange}
                                                // onBlur={date_onBlur}
                                                // name={date_name}
                                                // defaultDate={date_defaultValue}
                                                selected={date_defaultValue}
                                                onChange={(e) => {
                                                    setValue(answerKey, e)
                                                    // setValue(answerKey);
                                                    // flowbite doesn't pass an event
                                                    date_onChange({target: {value: e}});
                                                }}
                                            />
                                        </>)}
                                        {question.type === 'file' && (<>
                                            {/* <Label htmlFor={answerKey} value={"File"} /> */}
                                            <FileInput
                                                // helperText="Documentation is required for this indicator."
                                                id={answerKey}
                                                size={'sm'}
                                                // required={questionRequired}
                                                onChange={e => onFileChange(answerKey, e)}
                                                helperText={isUploadingFile ? 'Uploading...' : 'Files must be no larger than 50mb'}
                                                disabled={isUploadingFile}
                                            />

                                            {props.entry.media.filter(media => media.collection_name == answerKey).length > 0 && (
                                                <table className="table-auto w-full rounded overflow-hidden bg-white mt-6">
                                                    <tbody>
                                                    {props.entry.media.filter(media => media.collection_name == answerKey).map((media, mediaIndex) => (
                                                        <tr className={`text-sm font-semibold tracking-tight text-gray-600 px-3 py-1.5 ${mediaIndex % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`} key={mediaIndex}>
                                                            <td className="px-3 py-1.5"><a href={media.original_url} target="_blank">{media.file_name}</a></td>
                                                            <td className="px-3 py-1.5 text-right"><ConfirmDeleteFile media={media} onDelete={onDeleteFile} /></td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </>)}

                                    </div>
                                );
                            })}
                            </>)}
                            </>)}
                        </div>
                        <div className={`flex-initial`}>
                            <div className={`flex gap-3`}>
                                <div className="flex items-center gap-2">
                                    <Radio
                                        {...register(`lp_requirement_${requirement.id}`, {required: true, setValueAs2: (value) => (value === true ? 'yes' || value === false : 'no' || value),})}
                                        id={`lp_requirement_${requirement.id}_yes`}
                                        name={`lp_requirement_${requirement.id}`}
                                        value={'yes'}
                                    />
                                    <Label htmlFor={`lp_requirement_${requirement.id}_yes`}>Yes</Label>
                                </div>

                                <div className="flex items-center gap-2">
                                    <Radio
                                        {...register(`lp_requirement_${requirement.id}`, {
                                            required: true,
                                            setValueAs2: (value) => (value === 'true' ? true || value === 'false' : false || value),
                                            onChange: (e) => {
                                                if(e.target.value === 'no') {
                                                    if(!hasShownNoAnswerAlert) {
                                                        setIsShowingNoAnswerAlert(true);
                                                        setHasShownNoAnswerAlert(true);
                                                    }
                                                }
                                            }
                                        })}
                                        id={`lp_requirement_${requirement.id}_no`}
                                        name={`lp_requirement_${requirement.id}`}
                                        value={'no'}
                                    />
                                    <Label htmlFor={`lp_requirement_${requirement.id}_no`}>No</Label>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <p className="text-xs">Local Programs that do not meet these baseline requirements are not eligible for Accreditation from Main Street America, though they may qualify for designation at the Affiliate level.</p>
        </div>

        <div className="my-6 flex gap-6">
        {answersToShow.map(type => (
            <div className="flex-auto max-w-lg" key={type}>
                <p className="mb-3 text-md font-semibold tracking-tight text-blue-500">{accessNames[type]}</p>
                <div className="bg-white shadow px-6 py-6">

                <Label htmlFor={`${type}_notes`} value={"Notes"} />
                <NmsTextarea
                    id={`${type}_notes`}
                    {...register(`${type}_notes`, {
                        validate: val => {
                            if(val) {
                                return true;
                            }

                            // always force LP to provide note //if score is NOT a 3
                            const lp_answer = watch('lp_answer');

                            if(type === 'lp') {
                                // if(parseInt(lp_answer) != 3) {
                                    // if(!val) {
                                        return "Please elaborate your answer";
                                    // }
                                // }

                                // if LP, don't validate further
                                // return true;
                            }

                            // don't require MSA answers
                            if(type === 'msa') {
                                return true;
                            }

                            // don't require CP if MSA value is set
                            if(
                                type === 'cp'
                                && accessLevel === 'msa'
                                // && watched_msa_answer
                            ) {
                                return true;
                            }

                            // for CP / MSA, require explanation when answer is different
                            const this_answer = watch(`${type}_answer`);
                            if(parseInt(lp_answer) != parseInt(this_answer)) {
                                // if(!val) {
                                    return "Please explain why your answer is different";
                                // }
                            }

                            // return "Please elaborate your answer";
                            return true;
                        }
                    })}
                ></NmsTextarea>

                {/* {scoreNeedsExplanation(type) && (<em className="block mt-1 text-sm">{scoreNeedsExplanation(type)}</em>)} */}
                </div>
            </div>
        ))}
        </div>

        <ContinueButton />
        </form>
    </>);
}
